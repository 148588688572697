import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import ClientSearch from '../components/CatalogSearch'
import Layout from '../components/layout'
import get from 'lodash/get'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import SEO from '../components/seo'
import Hero from '../components/hero'


const CatalogTemplate = props => {
  const { pageContext } = props
  const { contentData } = pageContext
  const { allData, options } = contentData
  const heroImage = get(props, 'data.heroImage')
  const avatars = [
    get(props, 'data.detec'), 
    get(props, 'data.firetide'),
    get(props, 'data.iet'), 
    get(props, 'data.illustro'), 
    get(props, 'data.macro4'),
    get(props, 'data.memeo'), 
    get(props, 'data.softlanding'),
    get(props, 'data.teamblue'), 
    get(props, 'data.usi'),
    get(props, 'data.usr'),
    ]
  const site = get(props, 'data.site')   
  const post = get(props, 'data.markdownRemark')   
  return (
    <Layout>
      <div id="sub-page">
        <SEO 
          title="UNICOM Global product catalog" 
          description="Struggling to find a particular web page or piece of content? Use the UNICOM Global search to hone in on the information that you need."
          url={site.siteMetadata.siteUrl + props.location.pathname} 
          image={site.siteMetadata.siteUrl + heroImage.childImageSharp.gatsbyImageData.images.fallback.src}
        />        
      
      <Hero
          class="intro-40"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="search"
        />
      </div>

      <section className="bg-gray-light">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <ClientSearch content={allData} engine={options} avatars={avatars} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </Layout>
  )
}
export default CatalogTemplate

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: {name: {eq: "product-catalog"}}) {
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        alttext
        section {
          title 
          subtitle
          description 
          placement 
          btnlink
        }
      }
    }
    heroImage: file(name: { eq: "search" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
      }
    }
    detec: file(name: { eq: "detec-avatar" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 50, height: 50, quality: 90)
      }
    }
    firetide: file(name: { eq: "firetide-avatar" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 50, height: 50, quality: 90)
      }
    }
    iet: file(name: { eq: "iet-avatar" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 50, height: 50, quality: 90)
      }
    }
    illustro: file(name: { eq: "illustro-avatar" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 50, height: 50, quality: 90)
      }
    }
    macro4: file(name: { eq: "macro4-avatar" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 50, height: 50, quality: 90)
      }
    }
    memeo: file(name: { eq: "memeo-avatar" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 50, height: 50, quality: 90)
      }
    }
    softlanding: file(name: { eq: "sl-avatar" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 50, height: 50, quality: 90)
      }
    }
    teamblue: file(name: { eq: "teamblue-avatar" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 50, height: 50, quality: 90)
      }
    }
    usi: file(name: { eq: "usi-avatar" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 50, height: 50, quality: 90)
      }
    }
    usr: file(name: { eq: "usr-avatar" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 50, height: 50, quality: 90)
      }
    }       
  }
`