import React, { Component, Fragment } from 'react'
import * as JsSearch from 'js-search'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'

class CatalogSearch extends Component {
  state = {
    isLoading: true,
    searchResults: [],
    search: null,
    avatars: [], 
    isError: false,
    indexByTemplate: false,
    indexByName: false,
    indexByTitle: false,
    indexBySubtitle: false,
    indexByDate: false,
    indexByCategory: false,
    indexByPlatform: false,
    indexByHeadline: false,
    indexByBody: false,
    indexBySection: false,
    indexByTag: false,
    termFrequency: true,
    removeStopWords: false,
    searchQuery: '',
    selectedStrategy: '',
    selectedSanitizer: '',
  }
  /**
   * React lifecycle method that will inject the data into the state.
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.search === null) {
      const { engine } = nextProps
      return {
        indexByTemplate: engine.TemplateIndex,
        indexByName: engine.NameIndex,
        indexByTitle: engine.TitleIndex,
        indexBySubtitle: engine.SubtitleIndex,
        indexByDate: engine.DateIndex,
        indexByCategory: engine.CategoryIndex,
        indexByPlatform: engine.PlatformIndex,
        indexByHeadline: engine.HeadlineIndex,
        indexByBody: engine.BodyIndex,
        indexBySection: engine.SectionIndex,
        indexByTag: engine.TagIndex,
        termFrequency: engine.SearchByTerm,
        selectedSanitizer: engine.searchSanitizer,
        selectedStrategy: engine.indexStrategy,
      }
    }
    return null
  }
  async componentDidMount() {
    this.rebuildIndex()
    this.searchInput.focus()
  }

  /**
   * rebuilds the overall index based on the options
   */
  rebuildIndex = () => {
    const {
      selectedStrategy,
      selectedSanitizer,
      removeStopWords,
      termFrequency,
      indexByTemplate,
      indexByName,
      indexByTitle,
      indexBySubtitle,
      indexByDate,
      indexByCategory,
      indexByPlatform,
      indexByHeadline,
      indexByBody,
      indexBySection,
      indexByTag,
    } = this.state
    const { content } = this.props
    const { avatars } = this.props

    const dataToSearch = new JsSearch.Search(['id'])

    if (removeStopWords) {
      dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(
        dataToSearch.tokenizer
      )
    }
    /**
     * defines an indexing strategy for the data
     * read more about it here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    if (selectedStrategy === 'All') {
      dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    }
    if (selectedStrategy === 'Exact match') {
      dataToSearch.indexStrategy = new JsSearch.ExactWordIndexStrategy()
    }
    if (selectedStrategy === 'Prefix match') {
      dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
    }

    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     */
    selectedSanitizer === 'Case Sensitive'
      ? (dataToSearch.sanitizer = new JsSearch.CaseSensitiveSanitizer())
      : (dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer())
    termFrequency === true
      ? (dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex(['id',]))
      : (dataToSearch.searchIndex = new JsSearch.UnorderedSearchIndex())

    // sets the index attribute for the data
    if (indexByTemplate) {
      dataToSearch.addIndex(['template'])
    }
    // sets the index attribute for the data
    if (indexByName) {
      dataToSearch.addIndex(['name'])
    }
    // sets the index attribute for the data
    if (indexByTitle) {
      dataToSearch.addIndex(['title'])
    }
    // sets the index attribute for the data
    if (indexBySubtitle) {
      dataToSearch.addIndex(['subtitle'])
    }
    // sets the index attribute for the data
    if (indexByDate) {
      dataToSearch.addIndex(['date'])
    }
    // sets the index attribute for the data
    if (indexByCategory) {
      dataToSearch.addIndex(['category'])
    }
    // sets the index attribute for the data
    if (indexByPlatform) {
      dataToSearch.addIndex(['platform'])
    }
    // sets the index attribute for the data
    if (indexByHeadline) {
      dataToSearch.addIndex(['headline'])
    }
    // sets the index attribute for the data
    if (indexByBody) {
      dataToSearch.addIndex(['body'])
    }
    // sets the index attribute for the data
    if (indexBySection) {
      dataToSearch.addIndex(['section'])
    }
    // sets the index attribute for the data
    if (indexByTag) {
      dataToSearch.addIndex(['tags'])
    }

    dataToSearch.addDocuments(content) // adds the data to be searched

    this.setState({ search: dataToSearch, avatars: avatars, isLoading: false })
  }
  /**
   * handles the input change and perform a search with js-search
   * in which the results will be added to the state
   */
  searchData = e => {
    const { search } = this.state  
    const queryResult = search.search(e.target.value).sort((a,b) => a.id - b.id)
    this.setState({ searchQuery: e.target.value, searchResults: queryResult })
  }
  handleSubmit = e => {
    e.preventDefault()
  }
  handleClear = () => {
    this.setState({ searchQuery: "", searchResults: [], btnActive: "" })
  }
  handleClick = (e) => {
    this.setState({ searchQuery: e, searchInput: e }) 
    const { search } = this.state  
    const queryResult = search.search(e).sort((a,b) => a.id - b.id)
    this.setState({ searchResults: queryResult, btnActive: e })  
    typeof window !== 'undefined' && document.querySelector("#search-input").scrollIntoView({
      behavior: 'smooth'
    })
  }
   render() {
    const { searchResults, searchQuery, avatars } = this.state
    const queryResults = searchQuery === '' ? this.state.search ? this.state.search._documents : searchResults : searchResults
    return (
      <MDBContainer>
        <div id="search-input" className="search-input">&nbsp;</div>
        <MDBRow className="pb-5">
          <MDBCol className={`search-wrapper ${searchQuery ? 'pb-3' : 'pb-5'}`} md="12">
            <form
              onSubmit={this.handleSubmit}
              className="form-inline mt-2 mb-1 justify-content-center"
            >
              <FontAwesomeIcon icon={faSearch} />
              <input
                className="form-control form-control-lg ml-3 w-75 text-xs-large text-large search-box"
                type="text"
                ref={input => { this.searchInput = input }}
                id="Search"
                value={searchQuery}
                onChange={this.searchData}
                placeholder={this.props.context === "blog" ? "Search blogs..." : "Please enter your search phrase..."} 
              />
              <button className="close-icon" type="reset" onClick={this.handleClear}></button>
            </form>
          </MDBCol>

          {searchQuery &&
          <MDBCol>
            <h2 className="mb-5 font-alt font-w-700 text-xs-large text-large text-uppercase text-center">
              Number of results: {queryResults.length}
            </h2>
          </MDBCol>
          }
        </MDBRow>

        {queryResults.map((item, index) => {
          let avatarIndex = 0
          let alttext = ""
          switch(item.platform) {
            case "DETEC":
              avatarIndex = 0
              alttext = "DETEC avatar"
              break 
            case "Firetide":
              avatarIndex = 1
              alttext = "Firetide avatar"
              break 
            case "iET Solutions":
              avatarIndex = 2
              alttext = "iET Solutions avatar"
              break 
            case "illustro":
              avatarIndex = 3
              alttext = "iET Solutions avatar"
              break   
            case "Macro 4":
              avatarIndex = 4
              alttext = "Macro 4 avatar"
              break 
            case "Memeo":
              avatarIndex = 5
              alttext = "Memeo avatar"
              break 
            case "SoftLanding":
              avatarIndex = 6
              alttext = "SoftLanding avatar"
              break 
            case "TeamBLUE":
              avatarIndex = 7
              alttext = "TeamBLUE avatar"
              break 
            case "UNICOM Systems":
              avatarIndex = 8
              alttext = "UNICOM Systems avatar"
              break   
            case "USR":
              avatarIndex = 9
              alttext = "USR avatar"
              break   
            default: 
              avatarIndex = 8
              alttext = "UNICOM Systems avatar"
            }
          
          let avatar = avatars[avatarIndex]
          return (
            <Fragment key={index}>
              <MDBRow key={`row_${item.id}`}>
                <MDBCol md="1">
                  {avatar &&
                    <GatsbyImage image={avatar?.childImageSharp?.gatsbyImageData} alt={alttext} className="alt-bg-image" onClick={() => this.handleClick(item.platform)}/>
                  }
                </MDBCol>

                <MDBCol md="11" lg="7">
                  <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium">
                    <a 
                      href={item.name === "index" ? "/" : item.name === "news" ? `/news/${item.slug}/` : item.slug} 
                      className="effect-noline" target="_blank" dangerouslySetInnerHTML={{ __html: item.title }} />
                  </h3> 
                  <p className="font-w-400 text-medium">
                    {item.body}
                  </p>
                </MDBCol>

                <MDBCol lg="4" className="tag-btn">
                  <MDBBtn 
                    className={this.state.btnActive === item.platform ? 'btn-mdb-select btn-sm active px-2' : 'btn-mdb-select btn-sm px-2'}
                    value={item.platform}
                    onClick={() => this.handleClick(item.platform)}
                  >
                    {item.platform}
                  </MDBBtn>
                  {item.tags &&
                    <>
                      {item.tags.sort().map((tag, index) => {
                        return (
                          <MDBBtn
                            key={index}
                            className={this.state.btnActive === tag ? 'btn-mdb-select btn-sm active px-2' : 'btn-mdb-select btn-sm px-2'}
                            value={tag}
                            onClick={() => this.handleClick(tag)}
                          >
                            {tag}
                          </MDBBtn>
                        )
                      })}
                    </>
                  }
                </MDBCol>
              </MDBRow>
              <hr className="my-5" />
            </Fragment>
          )
        })}
      </MDBContainer>
    )
  }
}

export default CatalogSearch